import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import slider1 from './img/slide1.jpg';
import slider2 from './img/slide2.jpg';
import slider3 from './img/slide3.jpg';
import slider4 from './img/slide4.jpg';

const Home = () => {
  return (
    <Carousel>




<Carousel.Item>
        <img
          className="d-block w-100"
          src={slider4}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>PARUL HOSPITAL</h3>
          <p>BEST MULTI-HOSPITAL IN LUCKNOW</p>
        </Carousel.Caption>
      </Carousel.Item>



      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slider1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>PARUL HOSPITAL</h3>
          <p>BEST MULTI-HOSPITAL IN LUCKNOW</p>
        </Carousel.Caption>
      </Carousel.Item>




      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slider2}
          alt="Second slide"
        />
        <Carousel.Caption>
        <h3>PARUL HOSPITAL</h3>
        <p>BEST MULTI-HOSPITAL IN LUCKNOW</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={slider3}
          alt="Third slide"
        />
        <Carousel.Caption>
        <h3>PARUL HOSPITAL</h3>
        <p>BEST MULTI-HOSPITAL IN LUCKNOW</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Home;
